export class Constants {
    public static litraGlowFilter = [
        { vendorId: 0x046d, productId: 0xc900, usagePage: 0xff43 },
    ];

    // commands you can send
    public static REQUEST_ON_OFF = 0x01;             //1 byte. 0 = 0ff, 1 = on
    public static WRITE_TOGGLE_ON_OFF = 0x1C;        //1 byte. 0 = off, 1 = on
    public static REQUEST_BRIGHTNESS_LUMEN = 0x31;   //2 bytes. ranges from 20 (dim) to 250 (bright)
    public static WRITE_BRIGHTNESS_LUMEN = 0x4C;     //2 bytes. ranges from 20 (dim) to 250 (bright)
    public static REQUEST_TEMPERATURE_KELVIN = 0x81; //2 bytes. ranges from 2700 (warm) to 6500 (cold), rounded to the nearest 100
    public static WRITE_TEMPERATURE_KELVIN = 0x9C;   //2 bytes. ranges from 2700 (warm) to 6500 (cold), rounded to the nearest 100

    // hard limits of the device
    public static TEMPERATURE_MIN = 2700;
    public static TEMPERATURE_MAX = 6500;
    public static TEMPERATURE_STEP = 100;
    public static BRIGHTNESS_MIN = 20;
    public static BRIGHTNESS_MAX = 250;


    // the commands that the physical buttons on the device triggered, comes in the event listener
    public static PRESS_ON_OFF = 0x00;
    public static PRESS_BRIGHTNESS_LUMEN = 0x10;
    public static PRESS_TEMPERATURE_KELVIN = 0x20;
}
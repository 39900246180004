import { Constants } from "./constants";
import { LitraDriver } from "./litra-driver";
import { LitraState } from "./litra-state";

export class LitraController {
    private driver!: LitraDriver;

    constructor(hidDevice: HIDDevice) {
        this.driver = new LitraDriver(hidDevice);
    }

    public async initialize(): Promise<void> {
        await this.driver.initialize();
    }

    public async deactivate(): Promise<void> {
        await this.driver.deactivate();
    }

    public async getState(): Promise<LitraState> {
        const state = await this.driver.getState();
        return {
            onOff: state[0],
            brightnessPercentage: this.percentageOfRange(state[1], Constants.BRIGHTNESS_MIN, Constants.BRIGHTNESS_MAX),
            temperatureStep: (state[2] - Constants.TEMPERATURE_MIN) / 100,
        }
    }

    public async signal(): Promise<void> {
        await this.turnOn();
        await new Promise((resolve) => setTimeout(resolve, 750));
        await this.turnOff();
        await new Promise((resolve) => setTimeout(resolve, 750));
        await this.turnOn();
        await new Promise((resolve) => setTimeout(resolve, 750));
        await this.turnOff();
    }

    public async turnOn(): Promise<void> {
        await this.driver?.setOnOff(true);
    }

    public async turnOff(): Promise<void> {
        await this.driver?.setOnOff(false);
    }

    public async setBrightness(brightnessPercentage: number): Promise<void> {
        await this.driver?.setBrightness(brightnessPercentage);
    }

    public async setTemperatureKelvin(step: number): Promise<void> {
        const temperature = Constants.TEMPERATURE_MIN + (step * Constants.TEMPERATURE_STEP);
        await this.driver?.setTemperatureKelvin(temperature);
    }

    private percentageOfRange(value: number, min: number, max: number): number {
        if (min >= max) {
            throw new Error("Invalid range");
        }
        return Math.round((value - min) / (max - min) * 100);
    }
}
import { useEffect, useState } from "react";
import {
  Button,
  Label,
  Slider,
  SliderProps,
  ToggleButton,
  makeStyles,
} from "@fluentui/react-components";
import React from "react";
import {
  WeatherSunny24Filled,
  WeatherMoon24Filled,
  BrightnessLow24Regular,
  Temperature24Regular,
} from "@fluentui/react-icons";
import { Constants } from "../../logic/constants";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: "5px",
    paddingBottom: "10px",
  },
  valign: {
    display: "flex",
    alignItems: "center",
    width: "180px",
  },
  button: {
    width: "105px",
  },
  lpad: {
    paddingLeft: "9px",
  },
});

export default function Settings({ promptHID, litraController }: any) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    async function synchronizeState(event: Event) {
      event.preventDefault();
      const states = await litraController.getState();
      setChecked(states.onOff);
      setSliderBrightnessValue(states.brightnessPercentage);
      setSliderTemperatureValue(states.temperatureStep);
    }

    (async () => {
      if (litraController) {
        await litraController.initialize();
        const states = await litraController.getState();
        setChecked(states.onOff);
        setSliderBrightnessValue(states.brightnessPercentage);
        setSliderTemperatureValue(states.temperatureStep);

        window.addEventListener("litrabuttonpressed", async (event) =>
          synchronizeState(event)
        );

        return window.removeEventListener(
          "litrabuttonpressed",
          synchronizeState
        );
      }
    })();
  }, [litraController]);

  const onToggleChecked = () => {
    setChecked(!checked);
    !checked ? litraController.turnOn() : litraController.turnOff();
  };

  const sliderBrightnessId = "brightness";
  const sliderTemperatureId = "temperature";

  const [sliderBrightnessValue, setSliderBrightnessValue] = React.useState(0);
  const onSliderBrightnessChange: SliderProps["onChange"] = (_, data) => {
    setSliderBrightnessValue(data.value);
    litraController.setBrightness(data.value);
  };

  const [sliderTemperatureValue, setSliderTemperatureValue] = React.useState(0);
  const onSliderTemperatureChange: SliderProps["onChange"] = (_, data) => {
    setSliderTemperatureValue(data.value);
    litraController.setTemperatureKelvin(data.value);
  };

  return (
    <div className={classes.root}>
      <ToggleButton
        className={classes.button}
        disabled={!litraController}
        checked={checked}
        icon={!checked ? <WeatherSunny24Filled /> : <WeatherMoon24Filled />}
        onClick={onToggleChecked}
      >
        {!checked ? "Turn on" : "Turn off"}
      </ToggleButton>
      <Button onClick={promptHID} disabled={litraController}>
        Connect device
      </Button>
      <div className={classes.valign}>
        <Label aria-hidden>
          <BrightnessLow24Regular />
        </Label>
        <Slider
          disabled={!litraController}
          aria-valuetext={`${sliderBrightnessValue}%`}
          value={sliderBrightnessValue}
          min={0}
          max={100}
          onChange={onSliderBrightnessChange}
          id={sliderBrightnessId}
        />
        <Label
          aria-hidden
          className={classes.lpad}
        >{`${sliderBrightnessValue}%`}</Label>
      </div>
      <div className={classes.valign}>
        <Label aria-hidden>
          <Temperature24Regular />
        </Label>
        <Slider
          disabled={!litraController}
          aria-valuetext={`${sliderTemperatureValue}`}
          value={sliderTemperatureValue}
          min={0}
          max={(Constants.TEMPERATURE_MAX - Constants.TEMPERATURE_MIN) / 100}
          onChange={onSliderTemperatureChange}
          id={sliderTemperatureId}
        />
        <Label aria-hidden className={classes.lpad}>
          {Constants.TEMPERATURE_MIN + sliderTemperatureValue * 100}k
        </Label>
      </div>
    </div>
  );
}

export class Utils {
    // https://stackoverflow.com/a/70789108
    public static waitForEvent(item: any, event: any): Promise<void> {
        return new Promise((resolve) => {
            const listener = () => {
                item.removeEventListener(event, listener);
                resolve();
            }
            item.addEventListener(event, listener);
        })
    }

    public static padRight(array: number[], minLength: number, fillValue?: number): number[] {
        return Object.assign(new Array(minLength).fill(fillValue), array);
    }
}
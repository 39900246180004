import {
  FluentProvider,
  Theme,
  teamsDarkTheme,
  teamsLightTheme,
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import App from "./App";

export default function Root() {
  const [mode, setMode] = useState<Theme>(teamsDarkTheme);

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        e.matches ? setMode(teamsDarkTheme) : setMode(teamsLightTheme);
      });

    // Initial theme
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? setMode(teamsDarkTheme)
      : setMode(teamsLightTheme);

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  return (
    <FluentProvider theme={mode}>
      <App />
    </FluentProvider>
  );
}

import { Constants } from "./constants";
import { Utils } from "./utils";

export class LitraDriver {
  private hidDevice: HIDDevice;

  private isOn?: boolean;
  private brightnessInLumen?: number;
  private temperatureInKelvin?: number;

  private buttonPressedEvent = new Event("litrabuttonpressed");

  constructor(hidDevice: HIDDevice) {
    this.hidDevice = hidDevice;
  }

  public async initialize(): Promise<void> {
    this.hidDevice.addEventListener("inputreport", async event => this.synchronizeDeviceStateWithModel(event));
    if (!this.hidDevice.opened) {
      await this.hidDevice.open();
      await this.refreshOnOff();
      await this.refreshBrightness();
      await this.refreshTemperature();
    }
  }

  public async deactivate() {
    if (this.hidDevice.opened) {
      await this.hidDevice.close();
    }
  }

  public async getState(): Promise<any> {
    return [this.isOn, this.brightnessInLumen, this.temperatureInKelvin];
  }

  public async setBrightness(brightnessPercentage: number): Promise<void> {
    const brightness = Math.ceil(20 + (brightnessPercentage * 2.3));
    this.brightnessInLumen = brightness;
    await this.hidDevice.sendReport( // for future expansion: brightness is stored as a 16-bit uint, which only applies to the Litra Beam
      0x11, Int8Array.from(Utils.padRight([0xFF, 0x04, Constants.WRITE_BRIGHTNESS_LUMEN, 0x00, brightness], 18, 0x00))
    );
  }

  public async setTemperatureKelvin(kelvin: number): Promise<void> {
    if (kelvin < Constants.TEMPERATURE_MIN || kelvin > Constants.TEMPERATURE_MAX) {
      throw new Error("Temperature out of range");
    }

    this.temperatureInKelvin = kelvin;
    await this.hidDevice.sendReport(
      0x11, Int8Array.from(Utils.padRight([0xFF, 0x04, Constants.WRITE_TEMPERATURE_KELVIN, kelvin >> 8, kelvin & 0xFF], 18, 0x00))
    );
  }

  public async setOnOff(isOn: boolean): Promise<void> {
    this.isOn = isOn;
    await this.hidDevice.sendReport(
      0x11, Int8Array.from(Utils.padRight([0xFF, 0x04, Constants.WRITE_TOGGLE_ON_OFF, Number(isOn)], 19, 0x00))
    );
  }

  private async synchronizeDeviceStateWithModel(event: HIDInputReportEvent) {
    const command = new DataView(event.data.buffer).getUint8(2);

    switch (command) {
      case Constants.PRESS_ON_OFF:
        this.isOn = !!new DataView(event.data.buffer).getUint8(3);
        dispatchEvent(this.buttonPressedEvent);
        break;
      case Constants.REQUEST_ON_OFF:
        this.isOn = !!new DataView(event.data.buffer).getUint8(3);
        break;
      case Constants.PRESS_BRIGHTNESS_LUMEN:
        this.brightnessInLumen = new DataView(event.data.buffer).getUint16(3, false);
        dispatchEvent(this.buttonPressedEvent);
        break;
      case Constants.REQUEST_BRIGHTNESS_LUMEN:
        this.brightnessInLumen = new DataView(event.data.buffer).getUint16(3, false);
        break;
      case Constants.PRESS_TEMPERATURE_KELVIN:
        this.temperatureInKelvin = new DataView(event.data.buffer).getUint16(3, false);
        dispatchEvent(this.buttonPressedEvent);
        break;
      case Constants.REQUEST_TEMPERATURE_KELVIN:
        this.temperatureInKelvin = new DataView(event.data.buffer).getUint16(3, false);
        break;
    }
  }

  private async refreshOnOff() {
    await this.hidDevice.sendReport(
      0x11, Int8Array.from(Utils.padRight([0xFF, 0x04, Constants.REQUEST_ON_OFF], 19, 0x00))
    );
    await Utils.waitForEvent(this.hidDevice, "inputreport");
  }

  private async refreshBrightness() {
    await this.hidDevice.sendReport(
      0x11, Int8Array.from(Utils.padRight([0xFF, 0x04, Constants.REQUEST_BRIGHTNESS_LUMEN], 19, 0x00))
    );
    await Utils.waitForEvent(this.hidDevice, "inputreport");
  }

  private async refreshTemperature() {
    await this.hidDevice.sendReport(
      0x11, Int8Array.from(Utils.padRight([0xFF, 0x04, Constants.REQUEST_TEMPERATURE_KELVIN], 19, 0x00))
    );
    await Utils.waitForEvent(this.hidDevice, "inputreport");
  }
}

import { useEffect, useState } from "react";
import { LitraController } from "../logic/litra-controller";
import { Constants } from "../logic/constants";
import Settings from "./settings/Settings";
import Preview from "./preview/Preview";
import { makeStyles, Text } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "650px",
  },
  text: {
    marginBottom: "14px",
  }
});

export default function App() {
  const classes = useStyles();
  const [litraController, setLitraController] = useState<LitraController>();

  useEffect(() => {
    (async () => {
      let devices = await navigator.hid.getDevices();
      devices.forEach(async (hidDevice) => {
        setLitraController(new LitraController(hidDevice));
      });
    })();
  }, []);

  async function promptHID() {
    const [hidDevice] = await navigator.hid.requestDevice({
      filters: Constants.litraGlowFilter,
    });
    if (hidDevice) {
      setLitraController(new LitraController(hidDevice));
    }
  }

  return (
    <div className={classes.root}>
      <Text block wrap align="center" className={classes.text}>This application allows you to control the Logitech Litra Glow through either your browser or as a PWA installable. Not compatible with mobile devices.</Text>
      <Settings
        promptHID={promptHID}
        litraController={litraController}
      ></Settings>
      <Preview />
    </div>
  );
}

import {
  Button,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
  Text,
} from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles({
  preview: {
    width: "640px",
    height: "320px",
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground3,
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    ...shorthands.border(
      tokens.strokeWidthThicker,
      "solid",
      tokens.colorPaletteAnchorBorderActive
    ),
  },
  active: {
    ...shorthands.border(
      tokens.strokeWidthThicker,
      "solid",
      tokens.colorPaletteGreenBorder2
    ),
  },
  error: {
    ...shorthands.border(
      tokens.strokeWidthThicker,
      "solid",
      tokens.colorPaletteRedBorder2
    ),
  },
  valign: {
    flexDirection: "row",
    justifyContent: "start",
    columnGap: "10px",
    display: "flex",
    alignItems: "center",
  },
});

export default function Preview() {
  const videoRef = useRef<HTMLVideoElement>(null);
  const classes = useStyles();

  const [isStreaming, setIsStreaming] = useState(false);
  const [status, setStatus] = useState("");
  const [mediaStream, setMediaStream] = useState<MediaProvider | null>(null);
  const [previewButtonDisabled, setPreviewButtonDisabled] = useState(false);

  useEffect(() => {
    if (mediaStream) {
      videoRef.current!.srcObject = mediaStream;
      videoRef.current!.onloadedmetadata = function (e) {
        videoRef.current!.play();
      };
    }
  }, [mediaStream]);

  async function toggleStreamCamVideo() {
    var constraints = { audio: true, video: { width: 640, height: 320 } };
    try {
      setStatus("");
      setPreviewButtonDisabled(true);
      if (mediaStream) {
        videoRef.current!.srcObject = null;
        (mediaStream as MediaStream)!
          .getTracks()
          .forEach((track) => track.stop());
        setMediaStream(null);
        setIsStreaming(false);
      } else {
        setMediaStream(await navigator.mediaDevices.getUserMedia(constraints));
        setIsStreaming(true);
      }
      setPreviewButtonDisabled(false);
    } catch (e) {
      if (e instanceof Error) {
        switch (e.name) {
          case "NotAllowedError":
            setStatus(
              "Please allow the browser to access the camera to use the preview feature."
            );
            break;
          case "NotReadableError":
            setStatus(
              "Please make sure your camera is not in use by another application."
            );
            break;
          default:
            setStatus("Error: " + e.message);
            break;
        }
        setPreviewButtonDisabled(false);
      }
    }
  }

  return (
    <div>
      <div id="container">
        <video
          ref={videoRef}
          muted
          autoPlay={true}
          id="videoElement"
          className={mergeClasses(
            classes.preview,
            isStreaming && classes.active,
            status !== "" && classes.error
          )}
        ></video>
      </div>
      <div className={classes.valign}>
        <Button disabled={previewButtonDisabled} onClick={toggleStreamCamVideo}>
          {!isStreaming ? "Start preview" : "Stop preview"}
        </Button>
        <Text>{status}</Text>
      </div>
    </div>
  );
}
